
import {defineComponent} from "vue";

import List from "@/components/cost-center/cost-center/List.vue";

export default defineComponent({
  name: "cost-center",
  components: {
    List,
  },
  setup() {
    return {

    };
  },
});
