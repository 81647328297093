
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CostCenterAdd from "@/components/cost-center/cost-center/AddNew.vue";
import CostCenterUpdate from "@/components/cost-center/cost-center/Update.vue";



export default defineComponent({
    name: "vat-rates",
    components: {
      CostCenterAdd,
      CostCenterUpdate,
    },

    setup() {
      onMounted(() => {
        setCurrentPageBreadcrumbs("Masraf Merkezi", ["Logo Kodları"]);
      });


      store.dispatch(Actions.ACCOUNTING_COST_CENTERS_LIST, {});
      const objAccCostCenter = computed(() => {
        return store.getters.accountingcostcentersList;
      })


      const deleteCostCenter = (ID) => {
        store.dispatch(Actions.ACCOUNTING_COST_CENTERS_LIST, {});
        Swal.fire({
          title: 'Emin misiniz?',
          text: "Kaydi silmek işlemi geri alınamaz.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Kaydı Sil',
          cancelButtonText: `Vazgeç`,
          confirmButtonColor: '#9c1d1d',
          cancelButtonColor: '#3085d6',
        }).then(function (result) {
          if (result.isConfirmed) {
            const payload1 = {
              ID: ID,
            }
            store.dispatch(Actions.ACCOUNTING_COST_CENTERS_DELETE, payload1)
                .then(() => {
                  store.dispatch(Actions.ACCOUNTING_COST_CENTERS_LIST, {});
                  Swal.fire(
                      'Silindi!',
                      'Kayit silme işlemi tamamlandı.',
                      'success'
                  )
                }).catch(() => {
              Swal.fire({
                text: store.getters.getAccountingCostCentersErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

          }
        });
      };


      const itemID = ref<number>(0);
      function setItems(ID) {
        itemID.value=ID;
      }


      return {
          itemID,
          setItems,
          deleteCostCenter,
          objAccCostCenter,

      };
    },
});
