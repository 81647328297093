
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {Field, Form} from "vee-validate";
import reservationProductType from "@/core/data/reservationProductsTypetypes";


export default defineComponent({
  name: "operation-transfer-company-update",
  components: {
    Field,
    Form
  },

  props: {
    itemID: String,
  },

  watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };

      store.dispatch(Actions.ACCOUNTING_COST_CENTERS_DETAILS, payloadit);
    },
  },


  setup(props) {

    onMounted(() => {
      store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {}).then((prmData) => {
        myDepartment.value = prmData.data;
      });
    });
    const myDepartment = ref([]);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    store.dispatch(Actions.ACCOUNTING_COST_CENTERS_DETAILS, {'ID': props.itemID});
    const myDetails = computed(() => {
      let computedData = store.getters.accountingcostcentersDetails;
      if(computedData.data !== undefined){
        return computedData.data;
      }
      return [];
    })


    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      const payload1 = {
        ID: props.itemID,
        DepartmentID: myDetails.value.DepartmentID,
        Product: myDetails.value.Product,
        CostCenter: myDetails.value.CostCenter,
        Remarks: myDetails.value.Remarks,
      }

      store.dispatch(Actions.ACCOUNTING_COST_CENTERS_EDIT, payload1)
          .then(() => {
            store.dispatch(Actions.ACCOUNTING_COST_CENTERS_LIST, {});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {

              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getAccountingCostCentersErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }

    return {
      reservationProductType,
      submitButtonRef,
      myDepartment,
      submit,
      myDetails,

    };
  },
});
